<template>
  <div class="hello">
    <header id="header">
        <div class="header clearfix">
            <div class="container">
                <div class="d-flex justify-content-between align-items-center box-header">
                    <a href="#" class="link-logo">
                        <img src="../assets/images/logo.png" alt="logo">
                    </a>
                    <div class="nav-wrapper">
                        <nav class="navbar navbar-expand-lg navbar-toggleable-xs navbar-toggleable-sm navbar-toggleable-md navbar-ct">
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
                                <span class="navbar-toggler-icon">
                                    <span class="line-navbar line-navbar-1"></span>
                                    <span class="line-navbar line-navbar-2"></span>
                                    <span class="line-navbar line-navbar-3"></span>
                                </span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarNav">
                                <ul class="navbar-nav link-scroll">
                                    <li class="nav-item d-lg-none"><div class="icon-close"><div class="image"><img src="../assets/images/icon-close.png" alt=""></div></div></li>
                                    <li class="nav-item"><a id="howworklink" href="" class="nav-link">How It Works</a></li>
                                    <li class="nav-item"><a id="shippinglink" href="" class="nav-link">Shipping Rates</a></li>
                                    <li class="nav-item"><a id="warehouselink" href="" class="nav-link">Warehouse Addresses</a></li>
                                    <li class="nav-item d-none d-lg-block"><a id="submitorderlink" href="" class="nav-link">Submit Order</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <main>
        <div class="section-home section-banner clearfix">
            <div class="wrap-banner">
                <div class="banner banner-1">
                <div class="container">
                    <div class="box-banner-form">
                        <div class="row">
                        <div class="col-12 col-lg-7">
                            <div class="banner-title">
                                <h1 class="title">EASY SHIPPING</h1>
                                <div class="sub-title d-none d-lg-block">Get the best rates on parcel delivery </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 d-none d-lg-block">
                            <div class="box-form">
                                <form>
                                    <div class="form-group">
                                        <div class="title">QUICK REQUEST</div>
                                    </div>
                                    <div class="form-group">
                                        <label for="name">Full Name</label>
                                        <input type="text" class="form-control" placeholder="Your Name" id="name" v-model="quick.name">
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" class="form-control" placeholder="email@example.com" id="email" v-model="quick.email">
                                    </div>
                                    <div class="form-group">
                                        <label for="contactname">Contact Number</label>
                                        <input type="number" class="form-control" placeholder="____ ____ ___" id="contactname" v-model="quick.phone">
                                    </div>
                                    <div class="form-group form-group-track">
                                        <label for="track">Tracking Number</label>
                                        <input type="text" class="form-control" placeholder="### ### ### ###" id="track" v-model="quick.tracking">
                                    </div>
                                    <div v-if="success" class="alert alert-success" role="alert">
                                      Thank you, we have received your request.
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-submit-ship" @click="sendEmail">SUBMIT</button>
                                    <div class="link-scroll">
                                        <a id="submitorderlink" href="#" class="link-form-easy-shipping">USE THE FULL SUBMIT FORM</a>
                                    </div>
                                </form>
                            </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-ct-easy-shopping d-lg-none">
                <div class="container">
                    <div class="box-form">
                        <form>
                            <div class="form-group">
                                <div class="title">QUICK REQUEST</div>
                            </div>
                            <div class="form-group">
                                <label for="name">Full Name</label>
                                <input type="text" class="form-control" placeholder="Your Name" id="name" v-model="quick.name">
                            </div>
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" placeholder="email@example.com" id="email" v-model="quick.email">
                            </div>
                            <div class="form-group">
                                <label for="contactname">Contact Number</label>
                                <input type="number" class="form-control" placeholder="____ ____ ___" id="contactname" v-model="quick.phone">
                            </div>
                            <div class="form-group">
                                <label for="track">Tracking Number</label>
                                <input type="text" class="form-control" placeholder="### ### ### ###" id="track" v-model="quick.tracking">
                            </div>
                            <div v-if="success" class="alert alert-success" role="alert">
                              Thank you, we have received your request.
                            </div>
                            <button type="submit" class="btn btn-primary btn-submit-ship" @click="sendEmail">SUBMIT</button>
                            <div class="link-scroll">
                                <a id="submitorderlink" href="#" class="link-form-easy-shipping">USE THE FULL SUBMIT FORM</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div id="howwork"></div>
        </div>
        <div class="section-home section-how-work clearfix">
            <div class="container">
                <h2 class="title">HOW IT WORKS</h2>
                <div class="note">Ipsum viverra elit lobortis non cursus at sed. Sit consequat, tortor at sem sollicitudin.</div>
                <div class="work-step">
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <div class="step step-1">
                                <div class="image">
                                    <img src="../assets/images/work-step-1.png" alt="">
                                </div>
                                <div class="d-flex flex-column box-content-step">
                                    <h3 class="title-step">STEP 1</h3>
                                    <div class="info">Shop at your favorite sites and ship to our warehouse address</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="step step-2">
                                <div class="image">
                                    <img src="../assets/images/work-step-2.png" alt="">
                                </div>
                                <div class="d-flex flex-column box-content-step">
                                    <h3 class="title-step">STEP 2</h3>
                                    <div class="info">Submit your tracking or Order no to our form</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="step step-3">
                                <div class="image">
                                    <img src="../assets/images/work-step-3.png" alt="">
                                </div>
                                <div class="d-flex flex-column box-content-step">
                                    <h3 class="title-step">STEP 3</h3>
                                    <div class="info">Pay the shipping charges</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="step step-4">
                                <div class="image">
                                    <img src="../assets/images/work-step-4.png" alt="">
                                </div>
                                <div class="d-flex flex-column box-content-step">
                                    <h3 class="title-step">STEP 4</h3>
                                    <div class="info">Delivery to your door</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="shipping"></div>
            </div>
        </div>
        <div class="section-home section-shipping-rates clearfix">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <div class="box-title">
                            <h2 class="title">Shipping Rates</h2>
                            <div class="sub-title">Air Freight - 5 to 7 Working Days</div>
                            <div class="note">*Working days do not include Saturday, Sunday, both in Singapore or product source country’s and public holidays. </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="wrap-shipping-rates">
                            <div class="box-shipping-rates d-flex flex-wrap">
                                <div class="item-shipping-rates">
                                    <div class="box-item-ship">
                                        <div class="name-country d-flex align-items-center">
                                            <div class="image"><img src="../assets/images/AU-icon.png" alt=""></div>
                                            <span class="name">AUS / SIN</span>
                                        </div>
                                        <div class="list-ship-rate">
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Base Charge</div>
                                                <div class="info-right">SGD 6.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">First 1 KG</div>
                                                <div class="info-right">SGD 9.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Add 500 GMS</div>
                                                <div class="info-right">SGD 4.50</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-shipping-rates">
                                    <div class="box-item-ship">
                                        <div class="name-country d-flex align-items-center">
                                            <div class="image"><img src="../assets/images/HK-icon.png" alt=""></div>
                                            <span class="name">HK / IND</span>
                                        </div>
                                        <div class="list-ship-rate">
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Base Charge</div>
                                                <div class="info-right">TBA</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">First 1 KG</div>
                                                <div class="info-right">TBA</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Add 500 GMS</div>
                                                <div class="info-right">TBA</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-shipping-rates d-none">
                                    <div class="box-item-ship">
                                        <div class="name-country d-flex align-items-center">
                                            <div class="image"><img src="../assets/images/SK-icon.png" alt=""></div>
                                            <span class="name">South Korea</span>
                                        </div>
                                        <div class="list-ship-rate">
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Base Charge</div>
                                                <div class="info-right">SGD 5.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">First 1 KG</div>
                                                <div class="info-right">SGD 12.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Add 500 GMS</div>
                                                <div class="info-right">SGD 6.00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-shipping-rates">
                                    <div class="box-item-ship">
                                        <div class="name-country d-flex align-items-center">
                                            <div class="image"><img src="../assets/images/AU-icon.png" alt=""></div>
                                            <span class="name">AUS / IND</span>
                                        </div>
                                        <div class="list-ship-rate">
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Base Charge</div>
                                                <div class="info-right">SGD 6.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">First 1 KG</div>
                                                <div class="info-right">SGD 28.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Add 500 GMS</div>
                                                <div class="info-right">SGD 14.00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-shipping-rates">
                                    <div class="box-item-ship">
                                        <div class="name-country d-flex align-items-center">
                                            <div class="image"><img src="../assets/images/IND-icon.png" alt=""></div>
                                            <span class="name">SIN / IND</span>
                                        </div>
                                        <div class="list-ship-rate">
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Base Charge</div>
                                                <div class="info-right">SGD 6.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">First 1 KG</div>
                                                <div class="info-right">SGD 25.00</div>
                                            </div>
                                            <div class="item-list-ship-rate d-flex flex-column flex-lg-row align-items-lg-center">
                                                <div class="info-left">Add 500 GMS</div>
                                                <div class="info-right">SGD 12.50</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="note-bottom">
                                *All goods imported via SHIP4ME are not granted GST relief. Should you need more information on GST, please visit Singapore Customs’ website. For more information, <span class="text-link-shipping-rates"><a href="#">click here.</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="warehouse"></div>
        </div>
        <div class="section-home section-warehouse clearfix d-none">
            <div class="container container-warehouse-sp">
                <h2 class="title">Warehouse Addresses</h2>
                <div class="box-info-warehouse d-none d-lg-block">
                    <div class="d-flex box-ct-warehouse">
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/AU-icon.png" alt=""></div>
                                    <span class="name">Australia</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">Suite 251, 284 Old, Northern Road Castle Hill, NSW 2154</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main"></div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/HK-icon.png" alt=""></div>
                                    <span class="name">Hong Kong</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main">1234</div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/SK-icon.png" alt=""></div>
                                    <span class="name">South Korea</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">성한제</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">인천광역시 서구 백범로 677번길 9(가좌동)</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main">22829</div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                        <div class="text-sub-main mb-2">010-3930-5097</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/IND-icon.png" alt=""></div>
                                    <span class="name">Indonesia</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main"></div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-info-warehouse box-info-warehouse-sp">
                    <div class="d-flex box-ct-warehouse">
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/AU-icon.png" alt=""></div>
                                    <span class="name">Australia</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">Suite 251, 284 Old, Northern Road Castle Hill, NSW 2154</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main"></div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/HK-icon.png" alt=""></div>
                                    <span class="name">Hong Kong</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main">1234</div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/SK-icon.png" alt=""></div>
                                    <span class="name">South Korea</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">성한제</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">인천광역시 서구 백범로 677번길 9(가좌동)</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main">22829</div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                        <div class="text-sub-main mb-2">010-3930-5097</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-ct-warehouse">
                            <div class="item-info-warehouse">
                                <div class="name-country d-flex align-items-center">
                                    <div class="image"><img src="../assets/images/IND-icon.png" alt=""></div>
                                    <span class="name">Indonesia</span>
                                </div>
                                <div class="recommend-warehouse">
                                    <div class="text-main">Name</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="text-main">Address</div>
                                    <div class="text-sub-main">Lorem Ipsum</div>
                                    <div class="box-postal">
                                        <div class="text-main">Postal</div>
                                        <div class="text-sub-main"></div>
                                    </div>
                                    <div class="box-phone">
                                        <div class="text-main">Phone</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="submitorder" class="section-home section-submit-order clearfix">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <h2 class="title">Submit Order</h2>
                        <div class="note">Please fill up to this form</div>
                        <div class="text-number d-none"><span class="icon-submit"><img src="../assets/images/icon-phone.png" alt=""></span>2222-222-222</div>
                        <div class="text-mail d-none d-lg-flex"><span class="icon-submit"><img src="../assets/images/icon-mail.png" alt=""></span>cs@ship4me.asia</div>
                    </div>
                    <div class="col-12 col-lg-7">
                        <div class="box-form-submit">
                            <form>
                                <div class="form-group row">
                                    <label for="name-sub" class="col-sm-4 col-form-label">Full Name</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" placeholder="Your Name" id="name-sub" v-model="quick.name">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="email-sub" class="col-sm-4 col-form-label">Email</label>
                                    <div class="col-sm-8">
                                        <input type="email" class="form-control" placeholder="email@example.com" id="email-sub" v-model="quick.email">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="phone-sub" class="col-sm-4 col-form-label">Phone Number</label>
                                    <div class="col-sm-8">
                                        <input type="number" class="form-control" placeholder="### ### ### ###" id="phone-sub" v-model="quick.phone">
                                    </div>
                                </div>
                                <div class="form-group row d-none">
                                    <label for="SelectWarehouse" class="col-sm-4 col-form-label">Forwarding Warehouse</label>
                                    <div class="col-sm-8">
                                        <div class="box-select-form">
                                            <select class="form-control custom-select" id="SelectWarehouse" v-model="quick.warehouse">
                                                <option value="" disabled selected>Select the  Warehouse</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row d-none">
                                    <label for="courier" class="col-sm-4 col-form-label">Courier Company</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" id="courier" v-model="quick.courier">
                                    </div>
                                </div>
                                <div class="form-group row d-none">
                                    <label for="track-sub" class="col-sm-4 col-form-label">Tracking Number</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" placeholder="### ### ### ###" id="track-sub" v-model="quick.tracking">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="Destination" class="col-sm-4 col-form-label">Destination Address</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" placeholder="Destination" id="Destination" v-model="quick.destination">
                                    </div>
                                </div>
                                <div class="form-group form-group-item-description row">
                                    <label for="Itemtextarea" class="col-sm-4 col-form-label">Item Description</label>
                                    <div class="col-sm-8">
                                        <textarea class="form-control" id="Itemtextarea" placeholder="Tell us more about this order" rows="3" v-model="quick.description"></textarea>
                                    </div>
                                </div>
                                <div v-if="success" class="alert alert-success" role="alert">
                                  Thank you, we have received your request.
                                </div>
                                <div class="box-btn-sub-order">
                                    <button type="submit" class="btn btn-primary btn-submit-ship" @click="sendEmail">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer id="footercontent" class="footer clearfix">
        <div class="container">
            <div class="content-footer content-top-footer d-flex flex-column flex-lg-row justify-content-between align-items-lg-end">
                <a href="#" class="link-logo text-center d-block">
                    <img src="../assets/images/logo.png" alt="logo">
                </a>
                <div class="d-flex flex-column flex-lg-row justify-content-between mt-4 mt-lg-0 mb-3 mb-lg-0 footer-link link-scroll">
                    <a id="howworklink" href="#" class="text-nowrap">How It Works</a>
                    <a id="shippinglink" href="#" class="text-nowrap">Shipping Rates</a>
                    <a id="warehouselink" href="#" class="text-nowrap">Warehouse Addresses</a>
                    <a href="tel:2222-222-222" class="text-nowrap phone-company d-none"><span class="icon-footer"><img src="../assets/images/icon-phone.png" alt=""></span>2222-222-222</a>
                    <a href="mailto:someone@example.com" class="text-nowrap mail-company"><span class="icon-footer"><img src="../assets/images/icon-mail.png" alt=""></span>cs@ship4me.asia</a>
                </div>
            </div>
            <div class="content-footer content-middle-footer name-company">
                2021 SHIP4ME ASIA. All Rights Reserved.
            </div>
            <div class="content-footer content-bottom-footer d-flex flex-column flex-lg-row d-lg-none">
                <a href="#" class="text-nowrap">Terms & Conditions</a>
                <a href="#" class="text-nowrap">Privacy Policy</a>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "HelloWorld",
  data () {
    return {
      quick: {
        name: null,
        email: null,
        phone: null,
        warehouse: null,
        courier: null,
        tracking: null,
        destination: null,
        description: null
      },
      success: false
    }
  },
  methods: {
    sendEmail: async function (event) {
      event.preventDefault()
      axios
        .post("https://r25h30uhjl.execute-api.ap-southeast-1.amazonaws.com/default/sendemail", {
          name: this.quick.name,
          email:  this.quick.email,
          phone: this.quick.phone,
          warehouse: this.quick.warehouse,
          courier: this.quick.courier,
          tracking: this.quick.tracking,
          destination: this.quick.destination,
          description: this.quick.description
        })
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.success = true
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() =>{
          this.quick = {
            name: null,
            email: null,
            phone: null,
            warehouse: null,
            courier: null,
            tracking: null,
            destination: null,
            description: null
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
